var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height fill-width overflow-hidden" },
    [
      _c("DataTable", {
        ref: "table",
        attrs: {
          "default-options": {
            sortBy: ["lastModifyTime"],
            sortDesc: [true],
          },
          headers: _vm.headers,
          "load-data": _vm.loadData,
          "item-key": "id",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "search",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { staticClass: "px-4" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autofocus: "",
                              clearable: "",
                              placeholder: "Please enter a keyword query",
                            },
                            model: {
                              value: _vm.query.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "name", $$v)
                              },
                              expression: "query.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "headerButtons",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row pb-1 px-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { depressed: "", tile: "" },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v(" Add item ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { depressed: "", tile: "", type: "submit" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.refresh(true)
                            },
                          },
                        },
                        [_vm._v(" Inquire ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", tile: "" },
                          on: {
                            click: function ($event) {
                              return _vm.refresh()
                            },
                          },
                        },
                        [_vm._v(" refresh ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(item.id)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" edit ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("edit")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { color: "red" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(item.id)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" delete ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("delete")])]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("ProjectSchema", {
        ref: "projectSchema",
        on: {
          addSuccess: _vm.handleAddSuccess,
          editSuccess: _vm.handleEditSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }